import globalStore from '@/services/global.state';
import {goTo} from './route';
import {SafeAny} from '@/types';
import {getSideList} from '@/services/global.service';
import {getAdjustAttribution} from './adjust';
import DeviceInfo from 'react-native-device-info';
import {homePage} from '@/config';
import {inApp} from './interface';

export function needLoginBeforeDownload() {
  return (
    !globalStore.token &&
    (new URL(location.href).searchParams.get('pixelid') != null ||
      localStorage.getItem('pixelid') != null)
  );
}

export function stopBeforeDownload() {
  if (needLoginBeforeDownload()) {
    goTo('Login', {backPage: homePage});
    return true;
  }
  return false;
}

export function getImgs(sucCall: SafeAny, failCall?: SafeAny) {
  getSideList()
    .then(r => {
      sucCall(r);
    })
    .catch(e => {
      failCall && failCall(e);
      console.log('e', e);
    });
}
export function getDeviceCode() {
  if (globalStore.isWeb) {
    const adid = getAdjustAttribution()?.adid;
    if (adid) {
      localStorage.setItem('adjust_adid', adid);
      return adid;
    } else {
      let localAdid = localStorage.getItem('adjust_adid');
      if (!localAdid) {
        localAdid =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);
        localStorage.setItem('adjust_adid', localAdid);
      }
      return localAdid;
    }
  } else {
    return DeviceInfo.getAndroidId();
  }
}
function getRegisterChannel(userInviteCode?: string) {
  const attr = getAdjustAttribution();
  const trackerName = attr && attr.tracker_name;
  return trackerName && trackerName !== 'Organic'
    ? 'Promotion' /*推广*/
    : userInviteCode // 只有落地页还有邀请码
    ? 'AppShare'
    : 'Organic';
}

function getDeviceInfo() {
  const ua = navigator.userAgent;
  const info = {
    deviceBrand: 'Unknown',
    deviceModel: 'Unknown',
  };
  try {
    if (inApp) {
      const matchStr = ua.split('Mobile')?.[1]?.split(';')?.[1];
      const [deviceBrand, deviceModel] =
        matchStr?.split(';')?.[0]?.split('/') || [];
      info.deviceBrand = deviceBrand || 'Unknown';
      info.deviceModel = deviceModel || 'Unknown';
    } else {
      if (ua.indexOf('iPhone') > -1) {
        console.log('iPhone');
        info.deviceBrand = 'iPhone';
        info.deviceModel =
          ua.split('Mobile/')?.[1]?.split(' ')?.[0] || 'Unknown';
      } else if (ua.indexOf('Android') > -1) {
        info.deviceBrand = 'Android';
        info.deviceModel =
          ua.split('Mobile')?.[1]?.split(' ')?.[0]?.split('/')?.[1] ||
          'Unknown';
      }
    }
  } catch (e) {
    console.log('Error:', e);
  }
  return info;
}

export function getAttribution(userInviteCode?: string) {
  return {
    registerChannel: getRegisterChannel(userInviteCode),
    platform: inApp ? 'app' : 'h5',
    ...getDeviceInfo(),
  };
}
